// promotionList.js
export const promotionList = [
  {
    id: 1,
    titleKey: "promotion.1.3.title",
    desc: "promotion.1.3.description",
    alt: "promotion.1.3.title",
    imgSmall: "/images/referralbonus.jpg",
    imgLarge: "/images/referral-banner.webp",
    promoClick: "/promotion/referral-bonus/",
    priority: 100,
    target: false
  },
  {
    id: 2,
    titleKey: "promotion.1.2.title",
    desc: "promotion.1.2.description",
    alt: "promotion.1.2.title",
    imgSmall: "/images/deposit-banner-small.jpg",
    imgLarge: "/images/deposit-banner.webp",
    promoClick: "/promotion/deposit-bonus/",
    priority: 99,
    target: false
  },
  {
    id: 3,
    titleKey: "promotion.1.title",
    desc: "promotion.1.description",
    alt: "promotion.1.title",
    imgSmall: "/images/reward-banner-small.jpg",
    imgLarge: "/images/reward-banner.webp",
    promoClick: "/promotion/reward-points/",
    priority: 98,
    target: false
  },
  {
    id: 6,
    titleKey: "promotion.6.title.1",
    desc: "promotion.6.desc.1",
    alt: "promotion.6.title",
    imgSmall: "/images/promotion/DailyWins_700x450.webp",
    imgLarge: "/images/promotion/DailyWins_1903x550.webp",
    promoClick: "/promotion/daily-wins/",
    priority: 91,
    target: false
  },
  //  {
  //    id: 7,
  //    titleKey: "promotion.7.title.1",
  //    desc: "promotion.7.desc.1",
  //    alt: "promotion.7.title",
  //    imgSmall: "/images/promotion/bng_bet_race_700x450_th.webp",
  //    imgLarge: "/images/promotion/bng_bet_race_1903x550_th.webp",
  //    promoClick: "/promotion/bng-bet-race/",
  //    priority: 87,
  //    target: false
  //  },
  // {
  //   id: 8,
  //   titleKey: "promotion.9.title.1",
  //   desc: "promotion.9.desc.1",
  //   alt: "promotion.9.title",
  //   imgSmall: "promotion.9.imageSml",
  //   imgLarge: "promotion.9.imageLrg",
  //   promoClick: "promotion.9.href",
  //   priority: 86,
  //   target: true
  // },
  {
    id: 9,
    titleKey: "promotion.11.title.1",
    desc: "promotion.11.desc.1",
    alt: "promotion.11.title",
    imgSmall: "promotion.11.imageSml",
    imgLarge: "promotion.11.imageLrg",
    promoClick: "/promotion/sbo-weekly-mission/",
    priority: 88,
    target: false
  },
  {
    id: 10,
    titleKey: "promotion.12.title.1",
    desc: "promotion.12.desc.1",
    alt: "promotion.12.title",
    imgSmall: "promotion.12.imageSml",
    imgLarge: "promotion.12.imageLrg",
    promoClick: "promotion.12.href",
    priority: 86,
    target: true
  }
];
